


















import { defineComponent } from '@vue/composition-api';
import { isNonEmptyString } from '@/util/string';
import { AddUviExportConfigurationInput } from '@/types/iot-portal';
import { AppCustomerAddVendorApiWizardControlVendorApiFragment } from '../__generated__/AppCustomerAddVendorApiWizardControlVendorApiFragment';
import {
  AppCustomerAddUviExportConfigMutation,
  AppCustomerAddUviExportConfigMutationVariables,
} from './__generated__/AppCustomerAddUviExportConfigMutation';
import addUviExportConfiguration from './add-uvi-export.gql';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'UviExport',
  props: {
    customerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isNonEmptyString,
    };
  },
  methods: {
    ...mapActions({
      ADD_TOAST_MESSAGES: 'ADD_TOAST_MESSAGES',
    }),

    async addUviExportProvider(
      input: AddUviExportConfigurationInput,
    ): Promise<AppCustomerAddVendorApiWizardControlVendorApiFragment | void> {
      try {
        const { data } = await this.$apollo.mutate<
          AppCustomerAddUviExportConfigMutation,
          AppCustomerAddUviExportConfigMutationVariables
        >({
          mutation: addUviExportConfiguration,
          variables: { input: { ...input, customerId: this.customerId, label: input.label || '' } },
        });

        if (!data) {
          throw new Error('Der Datenanbieter konnte nicht hinzugefügt werden!');
        }

        return data.addUviExportConfiguration.uviExportConfiguration;
      } catch (error) {
        this.ADD_TOAST_MESSAGES({
          messages: [{ text: 'Der Datenanbieter konnte nicht hinzugefügt werden.', class: 'error' }],
        });
      }
    },
  },
});
