







































import { defineComponent } from '@vue/composition-api';
import { ApolloQueryResult } from 'apollo-client';
import query from './view.gql';
import {
  AppCustomerUviExportConfigurationViewQuery,
  AppCustomerUviExportConfigurationViewQueryVariables,
} from './__generated__/AppCustomerUviExportConfigurationViewQuery';

export default defineComponent({
  name: 'UviExportConfigurationView',
  props: {
    customerId: {
      type: String,
      required: true,
    },
    vendorApiId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      customers: undefined as AppCustomerUviExportConfigurationViewQuery['customers'] | undefined,
    };
  },
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: any): AppCustomerUviExportConfigurationViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
      result(this: any, { data, stale }: ApolloQueryResult<AppCustomerUviExportConfigurationViewQuery>): void {
        if (data && data.customers) {
          if (data.customers.first.uviExportConfigurations.first === undefined && !stale) {
            this.$router.back();
          }
        }
      },
    },
  },
});
