















































import { defineComponent } from '@vue/composition-api';
import { EditUviExportConfigurationInput, TreeNodeType } from '@/types/iot-portal';
import { isString } from 'lodash';
import editMutation from './edit.gql';
import removeMutation from './remove.gql';
import query from './view.gql';
import {
  AppCustomerUviExportConfigurationSettingsViewEditMutation,
  AppCustomerUviExportConfigurationSettingsViewEditMutationVariables,
} from './__generated__/AppCustomerUviExportConfigurationSettingsViewEditMutation';
import {
  AppCustomerUviExportConfigurationSettingsViewRemoveMutation,
  AppCustomerUviExportConfigurationSettingsViewRemoveMutationVariables,
} from './__generated__/AppCustomerUviExportConfigurationSettingsViewRemoveMutation';
import {
  AppCustomerUviExportConfigurationSettingsViewQuery,
  AppCustomerUviExportConfigurationSettingsViewQuery_customers_first_uviExportConfigurations_first_treeNodes_items,
} from './__generated__/AppCustomerUviExportConfigurationSettingsViewQuery';
import { ApolloQueryResult } from 'apollo-client/core/types';

type FormData = Pick<EditUviExportConfigurationInput, 'consent' | 'label' | 'email' | 'treeNodeIds'>;

export default defineComponent({
  name: 'SettingsView',

  props: {
    customerId: {
      type: String,
      required: true,
    },
    vendorApiId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      customers: undefined as AppCustomerUviExportConfigurationSettingsViewQuery['customers'] | undefined,
      treeNodes: undefined as
        | AppCustomerUviExportConfigurationSettingsViewQuery_customers_first_uviExportConfigurations_first_treeNodes_items[]
        | undefined,
      implicitBoolean: true,
    };
  },

  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          customerId: this.customerId,
          vendorApiId: this.vendorApiId,
        };
      },
      result({ data }: ApolloQueryResult<AppCustomerUviExportConfigurationSettingsViewQuery>): void {
        if (data != undefined) {
          this.treeNodes = data.customers.first.uviExportConfigurations.first.treeNodes.items;
        }
      },
    },
  },

  computed: {
    rootIds(): string[] {
      return this.customerId ? [this.customerId] : [];
    },

    consentDescription(): string {
      return `Hiermit bestätige ich, dass die Intgeration zwischen der GK4.0 Plattform und Allpas aktiviert werden soll. Es sollen alle Daten anhand der gesetzten Konfiguration übermittelt oder empfangen werden. Bitte speichern Sie die neuen Einstellungen.`;
    },

    consumptionTreeNodeTypes(): TreeNodeType[] {
      return [TreeNodeType.Property, TreeNodeType.Directory, TreeNodeType.RootDirectory];
    },

    selectableTreeNodeTypes(): TreeNodeType[] {
      return [TreeNodeType.Property, TreeNodeType.RootDirectory, TreeNodeType.Directory];
    },
  },

  methods: {
    pluckIds<T>(objects: { id: T }[] | null): T[] {
      return objects === null ? [] : objects.map(({ id }) => id);
    },

    consentStatusText(consent: boolean): string {
      if (consent) {
        return `Die Integration wurde aktiviert. Es werden anhand der vorgenommenen Konfiguration Dateien importiert & Verbrauchsdaten bereitgestellt. Wenn Sie die Integration deaktivieren möchten, dann entfernen Sie den Haken unter Einverständniserklärung und speichern die neuen Einstellungen.`;
      }
      return `Die Integration wurde deaktiviert. Es werden aktuell keine Daten importiert oder Verbrauchsdaten bereitgestellt.`;
    },

    async edit(input: FormData): Promise<void> {
      const uviExportConfigurationId = this.customers?.first.uviExportConfigurations.first?.id;
      if (uviExportConfigurationId === undefined) {
        return;
      }

      const { data } = await this.$apollo.mutate<
        AppCustomerUviExportConfigurationSettingsViewEditMutation,
        AppCustomerUviExportConfigurationSettingsViewEditMutationVariables
      >({
        mutation: editMutation,
        variables: {
          input: {
            uviExportConfigurationId,
            ...input,
            label: input.label || '',
            email: input.email || '',
          },
        },
      });

      if (!data) {
        throw new Error('Die Konfiguration des Verbrauchsdatenaustausch konnte nicht gespeichert werden');
      }

      if (!data.editUviExportConfiguration.uviExportConfiguration) {
        throw new Error(
          `Die Konfiguration des Verbrauchsdatenaustausch konnte nicht gespeichert werden: ${data.editUviExportConfiguration.uviExportConfiguration}`,
        );
      }

      const text = `Die Konfiguration des Verbrauchsdatenaustausch wurde gespeichert`;
      this.$store.dispatch('ADD_TOAST_MESSAGES', { messages: [{ text }] });
    },

    async remove(): Promise<void> {
      const uviExportConfigurationId = this.customers?.first.uviExportConfigurations.first?.id;
      if (uviExportConfigurationId === undefined) {
        return;
      }

      if (!window.confirm('Die Verbrauchsdatenaustausch Konfiguration wurde entfernt')) {
        return;
      }

      const { data } = await this.$apollo.mutate<
        AppCustomerUviExportConfigurationSettingsViewRemoveMutation,
        AppCustomerUviExportConfigurationSettingsViewRemoveMutationVariables
      >({
        mutation: removeMutation,
        variables: { input: { uviExportConfigurationId } },
      });

      if (!data) {
        throw new Error('Die Verbrauchsdatenaustausch Konfiguration konnte nicht entfernt werden');
      }

      const text = `Die Verbrauchsdatenaustausch Konfiguration wurde entfernt`;
      this.$store.dispatch('ADD_TOAST_MESSAGES', { messages: [{ text }] });

      this.$router.push({ name: 'AppCustomer/Customer/VendorApis' });
    },

    isNonEmptyString(value: unknown): boolean {
      return isString(value) && value.length > 0;
    },
  },
});
